@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 767px) {
  .message-container {
      padding-left: 8px; /* Or any other value you prefer */
  }
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsTabular';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Poppins-Tabular-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsTabular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Tabular-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsTabular';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Poppins-Tabular-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsTabular';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins-Tabular-SemiBold.ttf') format('truetype');
}

html {
  font-family: 'Poppins', sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

::-webkit-scrollbar {
    background-color: transparent;
    border-radius: 10px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    @apply bg-scrollbar rounded-lg;
}

body {
    @apply bg-altBackground-light;
    zoom: 90%;
    height: 100%;
}

#root {
  height: 100%;
}

body.dark {
    @apply bg-altBackground-dark;
}

@keyframes waveAnimation {
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-1px);
    }
    40% {
        transform: translateY(-3px);
    }
    60% {
        transform: translateY(3px);
    }
    80% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0);
    }
}

.wave-dot-1 {
    animation: waveAnimation 1.5s infinite linear;
}

.wave-dot-2 {
    animation: waveAnimation 1.5s infinite linear;
    animation-delay: 0.1s;
}

.wave-dot-3 {
    animation: waveAnimation 1.5s infinite linear;
    animation-delay: 0.2s;
}

.preferences-dropdown {
    border-radius: 10px !important;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px 2px 15px !important;
    cursor: pointer !important;
    min-width: 90px;
    min-height: 37px;
}

.dropdown-placeholder {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.dropdown-menu {
    margin-top: 2px !important;
    border-radius: 10px !important;
}

.Dropdown-option {
    color: unset !important;
}

.Dropdown-option.is-selected {
    background-color: rgba(0, 167, 225, 0.2) !important;
}

.Dropdown-option:hover:not(.is-selected) {
    background-color: rgba(0, 167, 225, 0.05) !important;
}

.react-toggle--checked > .react-toggle-track {
  background-color: #00A7E1!important;
}

.ticker-container > .Dropdown-root {
  width: 100%;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: #00A7E1!important;
  left: 22px!important;
}

.react-toggle .react-toggle-thumb {
  width: 26px;
  height: 26px;
  top: 2px;
  left: 2px;
  border-color: #ffffff!important;
}

.react-toggle .react-toggle-track {
  width: 50px;
  height: 30px;
  /* @apply bg-altBackground-light; */
}

.dark .react-toggle .react-toggle-track {
  @apply bg-altBackground-dark;
}

.react-toggle:hover .react-toggle-track {
  /* @apply bg-altBackground-light !important; */
}

.dark .react-toggle:hover .react-toggle-track {
  @apply bg-altBackground-dark !important;
}

.react-toggle--checked:hover .react-toggle-track {
  @apply bg-brand !important;
}

.dark .react-toggle--checked:hover .react-toggle-track {
  @apply bg-brand !important;
}

.react-toggle .react-toggle-thumb {
  background-color: #ffffff;
}

.dark .react-toggle .react-toggle-thumb {
  background-color: #ffffff;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #ffffff;
}

.dark .react-toggle--checked .react-toggle-thumb {
  background-color: #ffffff;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none!important;
}

.react-toggle:active .react-toggle-thumb {
  box-shadow: none!important;
}

button {
  user-select: none;
  opacity: 1;
  transition-duration: 300ms;
}

button:hover {
  opacity: 0.7;
  cursor: 'pointer';
  transition-duration: 300ms;
}

button:active {
  opacity: 0.85;
}

.empty-state-scroll-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.empty-state-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.empty-state-scroll-container {
  animation-play-state: running;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  margin: 20px;
	top: 0;
  max-width: 850px;
}
.suggested-messages-container {
  animation: opacity 0.5s linear;
}

.empty-state-measure-scroll-container {
  margin-bottom: 0px;
  margin-top: 0px;
}

.empty-state-message {
  display: block;
  margin: 5px;
  cursor: pointer;
  background-color: rgba(0, 167, 225, 0.05);
}

.empty-state-message:hover {
  background-color: rgba(0, 167, 225, 0.1);
}

@keyframes opacity {
	0% { opacity: 0 }
	20% { opacity: 0 }
	100% { opacity: 1 }
}

.mobile-logo-wrapper img {
  height: 20px!important;
}

.generated-link {
  text-decoration: underline;
  color: #00A7E1;
}